/*eslint-disable */
// import GPathCareerInterModel from '../../Model/GPathCareerInter'
import request from '../../Utils/curl'

let GPathCareerInters = {
  /**
   * gpath_career_interList
   */
  async gpath_career_interList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "gpath_careers_inter_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at gpath_career_interList() and Exception:',err.message)
    }
  },

  /**
   * gpath_career_interView
   */
  async gpath_career_interView (context, gpathCareerInterId) {
    try {      
      let post_data = new FormData();
      post_data.append('gpci_id', gpathCareerInterId);
      return await request.curl(context, "gpath_careers_inter_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at gpath_career_interView() and Exception:",err.message)
    }    
  },

  /**
   * gpath_career_interAdd
   */
  async gpath_career_interAdd (context, gpath_career_interObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_career_interObj) {
        post_data.append(key, gpath_career_interObj[key]);
      }

      return await request.curl(context, "gpath_careers_inter_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_career_interAdd() and Exception:',err.message)
    }
  },

  /**
   * gpath_career_interEdit
   */
  async gpath_career_interEdit (context, gpath_career_interObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_career_interObj) {
        post_data.append(key, gpath_career_interObj[key]);
      }

      return await request.curl(context, "gpath_careers_inter_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_career_interEdit() and Exception:',err.message)
    }
  },

  /**
   * gpath_career_interDelete
   */
  async gpath_career_interDelete (context, gpathCareerInterId) {
    try{
      let post_data = new FormData();
      
      post_data.append('gpci_id', gpathCareerInterId);

      return await request.curl(context, "gpath_careers_inter_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at gpath_career_interDelete() and Exception:',err.message)
    }
  }
}

export {
  GPathCareerInters
}
